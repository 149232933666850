<template>
  <div class="infor_content">
      <Scroll class="scroll_content" ref="scroll" :probeType="3" @scroll="contentScroll">
        <i class="iconfont icontuding"></i>
        <van-cell-group class="search">
          <div class="search_item" >
            性别: <span @click="search.showGender = true">{{search.gender}}</span>
          </div>
          <van-popup v-model:show="search.showGender" position="top">
            <van-picker
              title="性别"
              show-toolbar
              :columns="search2.genderColumns"
              @confirm="searchGenderOnConfirm"
              @cancel="search.showGender = false"
            />
          </van-popup>
          <div class="search_item" >
            年龄: <span @click="search.showBirthday = true">{{search.birthday}}</span>
          </div>
          <van-popup v-model:show="search.showBirthday" position="top">
            <van-picker
              title="年龄"
              show-toolbar
              :columns="search2.birthdayColumns"
              @confirm="searchBirthdayOnConfirm"
              @cancel="search.showBirthday = false"
            />
          </van-popup>
          <div class="search_item" >
            婚姻: <span @click="search.showMarriage = true">{{search.marriage}}</span>
          </div>
          <van-popup v-model:show="search.showMarriage" position="top">
            <van-picker
              title="婚姻"
              show-toolbar
              :columns="search2.marriageColumns"
              @confirm="searchMarriageOnConfirm"
              @cancel="search.showMarriage = false"
            />
          </van-popup>
          <div class="search_item" >
            学历: <span @click="search.showEducation = true">{{search.education}}</span>
          </div>
          <van-popup v-model:show="search.showEducation" position="top">
            <van-picker
              title="学历"
              show-toolbar
              :columns="search2.educationColumns"
              @confirm="searchEducationOnConfirm"
              @cancel="search.showEducation = false"
            />
          </van-popup>
          <div class="search_item" >
            照片: <span @click="search.showPhotoSearch = true">{{search.photo}}</span>
          </div>
          <van-popup v-model:show="search.showPhotoSearch" position="top">
            <van-picker
              title="照片"
              show-toolbar
              :columns="search2.photoColumns"
              @confirm="searchPhotoOnConfirm"
              @cancel="search.showPhotoSearch = false"
            />
          </van-popup>
          <div class="search_item">
            昵称编号: <span><input type="text" class="search_name" v-model="search.name" placeholder="昵称编号" size="8" /></span>
          </div>
          <div class="search_item">
            <span @click="reset">重置</span>
          </div>
          <div class="search_item">
            <span class="profile" @click="toProfile">我的资料</span>
          </div>
        </van-cell-group>
        <div class="info"></div>
        <van-cell-group class="search statement">
          <!-- <div><a href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe465bdb555d2d072&redirect_uri=http://cc.vipgz1.idcfengye.com/api/nt/wx/getGzhCode&response_type=code&scope=snsapi_userinfo#wechat_redirect">登录</a></div> -->
          <!-- <div><a href="http://localhost/api/nt/wx/getGzhOauth">登录</a></div> -->
          <!-- <div><a href="http://cc.vipgz1.idcfengye.com/api/nt/wx/getGzhOauth">登陆</a></div> -->
          <div>本帖仅为方便大家交流之用，</div>
          <div>并不做任何审核，</div>
          <div>信息真实与否由发布者自行负责</div>
          <div>对于本帖向下其他个人所提出的各种条件和要求，</div>
          <div>请各位自行考虑是否进行见面，聚会，相亲等环节</div>
        </van-cell-group>
        <div class="info"></div>
        <InformationsListItem v-for="(item, index) in infor" :key="index" 
        :infor="item" 
        :lastShow="(index+1)!=infor.length"
        @refresh="refresh"
        @actionSheetShows="actionSheetShows"/>
        <!-- <div class="height400" v-if="infor.length<4"></div> -->
    </Scroll>
    <BackTop @backTop_backClick="backTop_backClick" v-show="isShowBackTop" />
    <van-action-sheet
      v-model:show="actionSheetShow"
      :actions="actionSheetActions"
      cancel-text="取消"
      close-on-click-action
      @select="actionSheetOnSelect"
      @cancel="actionSheetOnCancel"
    />
  </div>
</template>
<script>
import {page} from 'network/informations.js'

import Scroll from "components/common/scroll/Scroll";
import { backTopMixin } from "common/mixin";
// import {debounce} from 'common/utils.js'

import InformationsListItem from "./childComps/InformationsListItem";

import tokenFunc from './token.js'
// https://blog.csdn.net/weixin_42088314/article/details/115700256
import useClipboard from 'vue-clipboard3'

export default {
  name: 'Informations',
  mixins: [backTopMixin],
  components: {
    InformationsListItem,
    Scroll
  },
  data() {
    return {
      search: {
        limit: 230,
        page: 1,
        name: '',
        gender: '所有',
        showGender: false,
        birthday: '所有',
        showBirthday: false,
        marriage: '所有',
        showMarriage: false,
        education: '所有',
        showEducation: false,
        photo: '所有',
        showPhoto: '',
        showPhotoSearch: false,
        timer: 500,
        timerExc: '',
      },
      search2: {
        genderColumns: ['所有','男','女'],
        birthdayColumns: ['所有','00-05','95-00','90-95','85-90','80-85','其他'],
        marriageColumns: ['所有','未婚','离异无孩','离异有孩'],
        educationColumns: ['所有', '博士','研究生','本科','其他'],
        photoColumns: ['所有','有照片'],
      },
      infor: [],
      setTimeout_clear: '',
      setTimeout_timer: 500,
      setTimeout_scroll_clear: '',
      setTimeout_scroll_timer: 500,
      actionSheetShow: false,
      actionSheetActions: [{ name: '复制' } ],
      listItemTxt: '',
    }
  },
  created() {
    if(this.$route.query.access_token){
      // this.setTokenTxt(this.$route.query.access_token)
      window.localStorage.setItem("tokenTxt",this.$route.query.access_token)
      // let tokenTxt = window.localStorage.getItem('tokenTxt')
      // console.log("tokenTxt:",tokenTxt);
    }
    setTimeout(() => {
      let txt = window.localStorage.getItem('tokenTxt')
      if(!txt){
        location.reload()
      }
    }, 500);
  },
  setup() {
    // let {tokenTxt, gettersChangeToken, setTokenTxt} = tokenFunc()
    // return {
    //   tokenTxt,
    //   gettersChangeToken,
    //   setTokenTxt,
    // }
    //对原始数据进行处理，拼接成自己需要的格式
	 function clickBtn(value){
      //处理数据格式
      let Msg = value
      //调用复制操作函数
      copy(Msg)
    }	
    const { toClipboard } = useClipboard()
    const copy = async (Msg) => {
      try {
      	//复制
        await toClipboard(Msg)
        //下面可以设置复制成功的提示框等操作
        //...
      } catch (e) {
      	//复制失败
        console.error(e)
      }
    }
    return { clickBtn }
  },
  mounted() {
    this.pages()
  },
  methods: {
    pages() {
      if(this.setTimeout_clear) { clearTimeout(this.setTimeout_clear) }
      this.setTimeout_clear = setTimeout(() => {
        // Reflect.setPrototypeOf(this.search,[]) //让原型变成数组
        // Reflect.getPrototypeOf(this.search) //得到原型
        // console.log(Reflect.getPrototypeOf(this.search));
        //重新返回一个新的json数组
        // Reflect.ownKeys(this.search).map(key => {
        //   return {key : this.search[key]}
        // })
        // Object.keys(this.search).forEach(key => {
        //   console.log(this.search[key])
        // })
        let params = {}
        Reflect.ownKeys(this.search).map(key => {
          if(this.search[key]=='所有') {
            return true
          }
          if(key=='gender') {
            params[key] = this.search[key] == '男' ? 1: 2
          } else if(key=='photo') {
            params[key] = this.search[key] == '有照片' ? 1: 0
          } else {
            params[key] = this.search[key]
          }
        })
        
        params.examined = '已审核'
        page(params).then(res => {
          if(res.code == 0) {
            this.infor = []
            setTimeout(() => {
              this.infor = res.data.map(k => {
                if(k.birthday && k.birthday.length>0){
                  k.birthday = k.birthday.substring(0,4)
                }
                return k
              });
            }, 200);
            // const newVal = JSON.parse(JSON.stringify(this.infor))
          } else {
            location.reload()
          }
        }).catch((error) => {})
      }, this.setTimeout_timer);
    },
    reset() {
      this.search.name = ''
      this.search.gender = '所有'
      this.search.birthday = '所有'
      this.search.marriage = '所有'
      this.search.education = '所有'
      this.search.photo = '所有'
    },
    toProfile() {
      this.$router.push({path: '/informations', query:{ id:'2',cc: 'daffda'}});
    },
    // Number.MAX_VALUE为最大的数
    contentScroll(p) {
      const py = -p.y;
      // switch (true) {
      //   case py > this.navBarClickOffsetTop[3]:
      //     this.$refs.DetailNavBar.currentIndex = 3;
      //     break;
      //   case py > this.navBarClickOffsetTop[2]:
      //     this.$refs.DetailNavBar.currentIndex = 2;
      //     break;
      //   case py > this.navBarClickOffsetTop[1]:
      //     this.$refs.DetailNavBar.currentIndex = 1;
      //     break;
      //   default:
      //     this.$refs.DetailNavBar.currentIndex = 0;
      //     break;
      // }
      // 1.判断BackTop是否显示
      this.listenShowBackTop(p);
    },
    actionSheetShows(d) {
      this.actionSheetShow = true
      this.listItemTxt = d
    },
    actionSheetOnSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.actionSheetShow = false;
      if(item.name=='复制') {
        this.clickBtn(this.listItemTxt)
        this.$toast.success('复制成功!');
      }
    },
    actionSheetOnCancel() {
      this.actionSheetShow = false
    },
    refresh() {
      if(this.setTimeout_scroll_clear) { clearTimeout(this.setTimeout_scroll_clear) }
      this.setTimeout_scroll_clear = setTimeout(() => {
        this.$refs.scroll.refresh()
      }, this.setTimeout_scroll_timer);
    },
    searchGenderOnConfirm(value, index) {
      this.search.gender = value
      this.search.showGender = false
    },
    searchBirthdayOnConfirm(value, index) {
      this.search.birthday = value
      this.search.showBirthday = false
    },
    searchEducationOnConfirm(value, index) {
      this.search.education = value
      this.search.showEducation = false
    },
    searchMarriageOnConfirm(value, index) {
      this.search.marriage = value
      this.search.showMarriage = false
    },
    searchPhotoOnConfirm(value, index) {
      this.search.photo = value
      this.search.showPhotoSearch = false
    },
    
  },
  watch: {
    'search.name'() {
      this.pages()
    },
    'search.gender'() {
      this.pages()
    },
    'search.birthday'() {
      this.pages()
    },
    'search.marriage'() {
      this.pages()
    },
    'search.education'() {
      this.pages()
    },
    'search.photo'() {
      this.pages()
    },
  },
}
</script>
<style scoped>
.infor_content {
  color: black;
  font-size: 18px;
  background-color: #F7F8FA;
}
.info {
  color: #8C99A0;
  font-size: 15px;
  padding-left: 16px;
  height: 5px;
}
.item {
  display:flex;
  justify-content: space-around;

  border-bottom: 1px solid #e3e3e3;
  line-height: 50px;
}
.input:focus {
  outline: none;
}
.search {
  display: flex;
  flex-wrap: wrap;

  font-size: 14px;
  padding: 8px 16px;
}
.search_item {
  color: var(--van-field-label-color);
  font-size: var(--van-font-size-lg);
  margin: 8px 10px;
  font-size:14px;
  color: black;
}
.search_name {
  padding: 0;
  border: 0;
}
.height5 {
  height: 5px;
}
.statement{
  color: #b22c46;
  background: #fff;
}
.scroll_content {
  /* height: calc(100% - 55px); */
  /* height: 100vh; */
  height: 100vh;
  overflow: hidden;
}
.height400 {
  height: 400px;
}
.profile {
  font-size: 14px;
  font-weight: bold;
}
</style>