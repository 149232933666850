<template>
  <div class="back-top">
    <!-- <img src="~assets/img/common/top.png" alt=""> -->
    <i class="iconfont icon-xiangshang" @click="backTop_backClick"></i>
  </div>
</template>

<script>
export default {
  name: "BackTop",
  data() {
    return {
      
    }
  },
  methods: {
    backTop_backClick() {
      this.$emit('backTop_backClick');
    },
  }
}
</script>
<style scoped>
.back-top {
  position: fixed;
  right: 8px;
  bottom: 55px;
}
.back-top i{
  font-size: 30px;
}
</style>