import {debounce} from './utils'

export const itemListenerMixin = {
  data() {
    return {
      itemImgListener: null,
      newRefresh: null
    }
  },
  mounted() {
     // 1.监听item中图片加载完成,防抖函数
     this.newRefresh = debounce(this.$refs.scroll.refresh, 200)
    
     // 对监听的事件进行保存
     this.itemImgListener = () => {
       this.newRefresh()
     }
     this.$bus.$on('itemImageLoad', this.itemImgListener)
     
  }
}
import BackTop from 'components/content/backTop/BackTop'
export const backTopMixin = {
  data() {
    return {
      isShowBackTop: false
    }
  },
  components: {
    BackTop
  },
  methods: {
    backTop_backClick() {
      this.$refs.scroll.scrollTo(0, 0, 500)
    },
    listenShowBackTop(p) {
      // 1.判断BackTop是否显示
      this.isShowBackTop = (-p.y) > 1000
    }
  }
}