<template>
  <div class="top_content">
    <div class="infor_item_content">
      <div class="item">编号:&nbsp;{{infor.bCode}}</div>
      <div class="item">婚姻:&nbsp;{{infor.marriage}}</div>
      <div class="item">
        性别:&nbsp;{{infor.gender==1? '男':'女'}}
        &nbsp;&nbsp;&nbsp;&nbsp;{{infor.onlySon==1?'独生': '非独生'}}
      </div>
      <div class="item">年龄:&nbsp;{{infor.birthday}}</div>
      <div class="item">身高/体重:&nbsp;{{infor.height}}/ {{infor.weight}}</div>
      <div class="item">学历:&nbsp;{{infor.education}}</div>
      <div class="item">职业/年薪:&nbsp;{{infor.occupation}}/&nbsp;{{infor.salary}}万/年</div>
      <div class="item">房/车:&nbsp;{{infor.house}}/ {{infor.car}}</div>
      <div class="item">籍贯:&nbsp;{{infor.nativePlace}}</div>
      <div class="item">居住:&nbsp;{{infor.liveCity}}</div>
      <!-- <div class="item">独子:&nbsp;{{infor.onlySon==1?'是': '否'}}</div> -->
      <div class="item">爱好:&nbsp;{{infor.hobby}}</div>
      <div class="item">自身情况:&nbsp;{{infor.selfSituation}}</div>
      <div class="item">父母情况:&nbsp;{{infor.parentsSituation}}</div>
      <div class="item">择偶要求:&nbsp;{{infor.requirements}}</div>
      <!-- <div class="item">身份证:&nbsp;{{infor.idCard}}</div>
      <div class="item">身份证正面:&nbsp;{{infor.idCardPic}}</div>
      <div class="item">身份证背面:&nbsp;{{infor.idCardPicB}}</div>
      <div class="item">学历照片:&nbsp;{{infor.educationPic}}</div> -->
      <div class="item photo" v-if="infor.showPhoto==1">照片:&nbsp;
        <van-image
          width="100"
          height="100"
          fit="cover"
          lazy-load
          @load="imageLoad"
          v-for="(item,index) in infor.photo.split(',')" :key="item"
          :src="baseapi+'/api/file/'+item"
          @click="imagePreviews(index)"
        />
      </div>
    </div>
    
    <div class="info" v-if="lastShow"></div>
    <div class="more" @click="actionSheetShows"><i class="iconfont icon-gengduo-copy"></i></div>
    
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  name: 'InformationsListItem',
  props: {
    infor: {
      type: Object,
      default: {}
    },
    // index: {
    //   type: Number,
    //   default: 0
    // },
    lastShow: {
      type: Boolean,
      default: true
    },
  },
  components: {
  },
  data() {
    return {
      baseapi:'',
      
    }
  },
  created() {
    this.baseapi = process.env.VUE_APP_BASE_API
  },
  mounted() {
    this.$emit('refresh');
  },
  methods: {
    imageLoad() {
      this.$emit('refresh');
    },
    imagePreviews(index) {
      let images = []
      this.infor.photo.split(',').forEach(element => {
        images.push(this.baseapi+'/api/file/'+element)
      });
      ImagePreview({
        images: images,
        showIndex: true,
        loop: false,
        startPosition: index
      });
      // ImagePreview({
      //   images: [
      //     'https://img01.yzcdn.cn/vant/apple-1.jpg',
      //     'https://img01.yzcdn.cn/vant/apple-2.jpg',
      //   ],
      //   startPosition: 1,
      // });
    },
    actionSheetShows() {
      let infor2 = '编号: ' + this.infor.bCode + '\r\n'
          + '婚姻: ' + this.infor.marriage + '\r\n'
          + '性别: ' + (this.infor.gender==1? '男':'女')+ '    ' + (this.infor.onlySon==1?'独生': '非独生') + '\r\n'
          + '年龄: ' + this.infor.birthday + '\r\n'
          + '身高/体重: ' + this.infor.height + '/ ' + this.infor.weight + '\r\n'
          + '学历: ' + this.infor.education + '\r\n'
          + '职业/年薪: ' + this.infor.occupation + '/ ' + this.infor.salary + '万/年\r\n'
          + '房/车: ' + this.infor.house + '/ ' + this.infor.car + '\r\n'
          + '籍贯: ' + this.infor.nativePlace + '\r\n'
          + '居住: ' + this.infor.liveCity + '\r\n'
          + '爱好: ' + this.infor.hobby + '\r\n'
          + '自身情况: ' + this.infor.selfSituation + '\r\n'
          + '父母情况: ' + this.infor.parentsSituation + '\r\n'
          + '择偶要求: ' + this.infor.requirements + '\r\n'
      this.$emit("actionSheetShows", infor2)
    },
  },
  
}
</script>

<style scoped>

.top_content {
  position: relative;
}
.infor_item_content {
  color: black;
  font-size: 18px;
  background-color: #fff;
  padding: 8px 16px;
}
.info {
  color: #8C99A0;
  background-color: #F7F8FA;
  font-size: 15px;
  padding-left: 16px;
  height: 5px;
}
.item {
  word-wrap:break-word;
  word-break:break-all; 
  line-height: 26px;
  font-size: 15px;
}
.van-image {
  margin-right: 5px;
}
.photo {
  display: flex;
  align-items:flex-start;
}
.more {
  position: absolute;
  right:12px;
  top: 10px;
  font-size: 16px;
  color: #ed1941;
  padding: 0 5px 5px 5px;
}
</style>
